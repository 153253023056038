<template>
    <styled-interface>
        <template #interface-toolbar-external>
            <agency-toolbar-global
                hide-attribution
                hide-spend-override />
        </template>

        <template #interface-heading>
            Reporting
        </template>

        <styled-card class="advertising-card">
            <template #heading>
                {{ currentPlatform.name }} Advertising
            </template>
            <template
                #action-buttons>
                <styled-tooltip v-if="!loading && failedDealers.length">
                    <template #content>
                        <p>
                            The following accounts had no data
                            in the time period selected or failed
                            for another reason:
                        </p>
                        <ul>
                            <li
                                v-for="dealer in failedDealers"
                                :key="dealer.id">
                                {{ dealer.name }}
                            </li>
                        </ul>
                    </template>
                    <a
                        href="#"
                        @click.prevent="() => null">
                        No metrics for {{ failedDealers.length }} accounts
                    </a>
                </styled-tooltip>
                <action-button
                    icon="refresh"
                    :disabled="loading"
                    @click="loadData">
                    refresh
                </action-button>
                <action-button
                    icon="download"
                    :disabled="loading || loadingDealers || !hasConfirmedLoad"
                    @click="exportCSV">
                    Export CSV
                </action-button>
            </template>
            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global :disabled="loading" />
                </div>
            </div>
            <div
                v-if="loadingDealers || groupsLoading"
                class="ma-5">
                <loader label="Loading accounts..." />
            </div>
            <load-confirmation
                v-else-if="!hasConfirmedLoad && !loadingDealers"
                :report-name="currentPlatform.name + ' Advertising'"
                :dealers-total="dealersTotal"
                :estimated-time="estimatedTime"
                data-type="advertising"
                @load="handleLoadConfirmation" />
            <div
                v-else-if="loading || groupsLoading"
                class="metrics-loader">
                <styled-progress-bar
                    :value="loadingPercent / 100" />
                <div class="text-xs-center mt-4">
                    {{ loadingMessage }}
                </div>
            </div>
            <component
                :is="currentPlatform.tableComponent"
                v-else
                :items="activeDealersMetrics"
                @make-sync="getStats" />
        </styled-card>
    </styled-interface>
</template>

<script>
import Loader from '@/components/globals/Loader';
import LoadConfirmation from '@/components/globals/LoadConfirmation';
import StyledButton from '@/components/globals/StyledButton';
import StyledDialog from '@/components/globals/StyledDialog';
import DateRangePickerPresets from '@/components/globals/DateRangePickerPresets';
import AgencyToolbarGlobal from '@/components/globals/AgencyToolbarGlobal';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import StyledProgressBar from '@/components/globals/StyledProgressBar.vue';
import StyledCard from '@/components/globals/StyledCard.vue';
import StyledInterface from '@/components/globals/StyledInterface.vue';
import ActionButton from '@/components/globals/ActionButton.vue';
import SnapchatTable from './channels/SnapchatTable.vue';
import TikTokTable from './channels/TikTokTable.vue';
import PinterestTable from './channels/PinterestTable.vue';
import getSnapchatMetrics from '@/apis/snapchat/getDealerMetrics';
import getPinterestMetrics from '@/apis/pinterest/getDealerMetrics';
import getTikTokMetrics from '@/apis/tiktok/getDealerMetrics';
import sleep from '@/helpers/sleep';
import { ExportToCsv } from 'export-to-csv';
import { mapState } from 'vuex';
import chunk from 'lodash/chunk';

const PLATFORMS = {
    tiktok: {
        id: 8,
        name: 'TikTok',
        batchSize: 2,
        estimatedTimePer: 600,
        sleep: 0,
        api: getTikTokMetrics,
        tableComponent: TikTokTable
    },
    snapchat: {
        id: 9,
        name: 'Snapchat',
        batchSize: 5,
        estimatedTimePer: 300,
        sleep: 0,
        api:  getSnapchatMetrics,
        tableComponent: SnapchatTable
    },
    pinterest: {
        id: 10,
        name: 'Pinterest',
        batchSize: 1,
        estimatedTimePer: 1200,
        sleep: 1000,
        api: getPinterestMetrics,
        tableComponent: PinterestTable
    }
};

export default {
    components: {
        Loader,
        LoadConfirmation,
        StyledTooltip,
        StyledInterface,
        StyledCard,
        ActionButton,
        StyledProgressBar,
        DateRangePickerPresets,
        AgencyToolbarGlobal,
        GroupsPickerGlobal,
        StyledDialog,
        StyledButton
    },
    props: {
        platform: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dealersTotal: 0,
            hasConfirmedLoad: false,
            activeDealersMetrics: [],
            activeDealers: [],
            failedDealers: [],
            loading: false,
            loadingDealers: false,
            loadingMessage: '',
            loadingPercent: 0,
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            dateRange: (state) => state.metrics.dateRange,
            currentAgencyId: (state) => state.agency.currentAgency.id,
            selectedGroups: (state) => state.agency.selectedGroups,
            groupsLoading: (state) => state.agency.groupsLoading,
        }),
        currentPlatform() {
            return PLATFORMS[this.platform];
        },
        selectedGroupsIds() {
            return this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
        },
        estimatedTime() {
            const { estimatedTimePer } = this.currentPlatform;
            const count = this.activeDealers.length;
            const time = (estimatedTimePer * count) / 1000;
            const minutes = (time / 60) | 0;
            const seconds = (time % 60) | 0;
            const parts = [];
            if (minutes > 0) { parts.push(`${minutes} minutes`) }
            if (seconds > 0) { parts.push(`${seconds} seconds`) }
            return parts.join(' and ');
        },
    },
    watch: {
        dateRange() {
            this.loadData();
        },
        selectedGroups: {
            handler() {
                this.loadData();
            },
            deep: true,
        },
    },
    mounted() {
        this.setTitle();
        this.loadData();
    },
    methods: {
        async getStats(channel, dealerId) {
            await this.makeFullSync(channel, dealerId);
            await this.loadData();
        },
        async makeFullSync(channel, dealerId) {
            this.loading = true;
            try {
                await this.$apiRepository.queueDealerAdStatsSync({ dealerId, platform: channel });
            } catch(error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        reset() {
            this.hasConfirmedLoad = false;
            this.activeDealers = [];
            this.activeDealersMetrics = [];
            this.failedDealers = [];
        },
        setTitle() {
            this.$title = `${this.currentPlatform.name} Advertising - ${this.agency.name}`;
        },
        async loadData() {
            this.loadingDealers = true;
            this.reset();
            this.loadingMessage = `Loading accounts on ${this.currentPlatform.name}...`;
            this.loadingPercent = 0;
            await this.getActiveDealers();
            if (this.selectedGroupsIds.length) {
                const response = await this.$apiRepository.getAccountGroupsQuery(null, null, null, this.agency.id, this.selectedGroupsIds);
                const groups = response.data.data;
                const uniqueDealers = new Set();
                groups.forEach(group => {
                    group.dealers.data.forEach(dealer => {
                        uniqueDealers.add(dealer.id);
                    });
                });
                this.dealersTotal = uniqueDealers.size;
            } else {
                this.dealersTotal = this.activeDealers.length;
            }
            this.loadingDealers = false;
        },
        async handleLoadConfirmation() {
            this.hasConfirmedLoad = true;
            this.loading = true;
            this.loadingPercent = 0;

            await this.getAllDealerMetrics();

            this.loadingMessage = 'Finished loading.';

            await sleep(1000);

            this.loading = false;
            this.loadingMessage = '';
            this.loadingPercent = 0;
        },
        async getAllDealerMetrics() {
            const request = async(dealer) => {
                const adAccount = dealer?.ad_accounts?.data.find(
                    item => item.platform_id === this.currentPlatform.id
                );
                const adAccountId = adAccount ? adAccount.external_ad_account_id : 'N/A';

                try {
                    const response =  await this.currentPlatform.api({
                        dealerId: dealer.id,
                        ...this.dateRange
                    });
                    const metrics = response?.series?.[0]?.stats || null;

                    return {
                        // Trim to critical data
                        dealer_name: dealer.name,
                        dealer_id: dealer.id,
                        ad_account_id: adAccountId,
                        running: {
                            value: false,
                            status: ''
                        },
                        metrics
                    };
                } catch (error) {
                    if (error.response.data.full_sync == 'running' ||
                        error.response.data.incremental_sync == 'running' ||
                        error.response.data.full_sync == 'queued' ||
                        error.response.data.incremental_sync == 'queued') {
                        return {
                            dealer_name: dealer.name,
                            dealer_id: dealer.id,
                            ad_account_id: adAccountId,
                            running: {
                                value: true,
                                status: 'running'
                            },
                        };
                    } if (error.response.data.full_sync == 'failed' || error.response.data.incremental_sync == 'failed') {
                        return {
                            dealer_name: dealer.name,
                            dealer_id: dealer.id,
                            ad_account_id: adAccountId,
                            running: {
                                value: true,
                                status: 'failed'
                            },
                        };
                    } else {
                        this.failedDealers.push(dealer);
                        return null;
                    }
                }
            };

            const chunks = chunk(this.activeDealers, this.currentPlatform.batchSize);

            let allData = [];

            for (let i = 0; i < chunks.length; i++) {

                this.loadingMessage = `Loading metrics: ${Math.round(this.loadingPercent)}%`;

                const data = await Promise.all(chunks[i].map(request));
                allData = allData.concat(data);

                if (this.currentPlatform.sleep > 0) {
                    await sleep(this.currentPlatform.sleep);
                }

                this.loadingPercent += (100 / chunks.length);
            }

            this.activeDealersMetrics = allData.filter(item => item !== null).map(Object.freeze);
        },
        async getActiveDealers() {
            try {
                let dealers = [];

                const request = async(pageNumber) => {

                    const response = await this.$apiRepository.getAgencyDealersWithPlatform({
                        selectedGroups: this.selectedGroupsIds ? this.selectedGroupsIds : null,
                        agencyId: this.currentAgencyId,
                        platformId: this.currentPlatform.id,
                        pageNumber
                    });

                    dealers = dealers.concat(response.data.data);

                    if (response.data.meta.current_page !== response.data.meta.last_page) {
                        await request(pageNumber + 1);
                    }
                };

                await request(1); // Kick off first page request

                this.activeDealers = dealers.map(Object.freeze);
            } catch(error) {
                console.error('Error retrieving dealers:', error);
            }
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `${this.platform}_advertising_${new Date().getTime()}`
            };
            const csvExporter = new ExportToCsv(options);
            const result = this.activeDealersMetrics.reduce(function(result, dealer) {
                const obj = {
                    dealer_id: dealer.dealer_id,
                    dealer_name: dealer.dealer_name,
                    ad_account_id: dealer.ad_account_id
                };
                const dealerMetrics = dealer.metrics ?? null;
                if (dealerMetrics) {
                    for (const [key, value] of Object.entries(dealerMetrics)) {
                        obj[key] = value.formatted;
                    }
                    result.push(obj);
                }
                return result;
            }, []);
            csvExporter.generateCsv(result);
        }
    }
};
</script>

<style lang="scss" scoped>
.advertising-card {
    overflow: visible;
}
.metrics-loader {
    padding: 80px 60px;
}

.date-dialog {
    margin: 60px auto;
    padding: 20px;
    border: 1px solid $gray-light;
    background-color: $alabaster;
    max-width: 600px;
    @media (max-width: $bp-md) {
        margin: 60px 40px;
    }
}
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 30px;
    &__holder {
        max-width: 255px;
    }
}
</style>

<style lang="scss">
</style>
